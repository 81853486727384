/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import useForm, {
  STATUS_SENT,
  STATUS_ERROR,
  STATUS_SENDING,
} from 'hooks/useForm';
import useAotuAPI from 'hooks/useAotuAPI';
import Button from 'components/Button';
import './EmailListSignup.module.css';

const subscribeFormHandler = (aotuAPI) => (body) => {
  const { email, list_id, ...custom_fields } = body;
  return aotuAPI.subscribe(list_id, email, custom_fields);
};

const BUTTON_TEXT = {
  [STATUS_SENT]: 'common.sent',
  [STATUS_SENDING]: 'common.sending',
};

function EmailListForm({
  intl,
  listId,
  compact,
}) {
  const aotuAPI = useAotuAPI();

  const [
    handleChange,
    handleSubmit,
    {
      email = '',
    },
    status,
  ] = useForm(subscribeFormHandler(aotuAPI), { list_id: listId, locale: intl.locale });
  const buttonText = BUTTON_TEXT[status] || 'common.sign-up';

  return (
    <div styleName="form-container">
      <form onSubmit={handleSubmit} styleName="form" data-compact={compact}>
        <label htmlFor="form-email">
          <input
            type="email"
            id="form-email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'common.your-email' })}
            required
          />
        </label>

        <Button type="submit">
          {intl.formatMessage({ id: buttonText })}
        </Button>
      </form>

      {status === STATUS_SENT && (
        <div styleName="status-message">
          {intl.formatMessage({ id: 'common.subscribed' })}
        </div>
      )}

      {status === STATUS_ERROR && (
        <div styleName="status-message">
          {intl.formatMessage({ id: 'contact.send-failure' })}
          {' '}
          <a href="mailto:contact@aotu.ai">
            contact@aotu.ai
          </a>
          .
        </div>
      )}
    </div>
  );
}

EmailListForm.propTypes = {
  intl: intlShape.isRequired,
  listId: PropTypes.string,
  compact: PropTypes.bool,
};

EmailListForm.defaultProps = {
  listId: '',
  compact: false,
};

function EmailListSignup({ intl, compact }) {
  const data = useStaticQuery(graphql`
    query EmailListUpdates {
      site {
        siteMetadata {
          emailSubscribeListUpdates
        }
      }
    }
  `);
  const { emailSubscribeListUpdates } = data.site.siteMetadata;

  return (
    <div styleName="email-list-signup">
      {!compact && (
        <>
          <h5 styleName="title">
            {intl.formatMessage({ id: 'common.email-list-title' })}
          </h5>

          <p>
            {intl.formatMessage({ id: 'common.email-list-subtitle' })}
          </p>
        </>
      )}

      <EmailListForm
        intl={intl}
        listId={emailSubscribeListUpdates}
        compact={compact}
      />
    </div>
  );
}

EmailListSignup.propTypes = {
  intl: intlShape.isRequired,
  compact: PropTypes.bool,
};

EmailListSignup.defaultProps = {
  compact: false,
};

export default injectIntl(EmailListSignup);
