import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import Logo from 'components/Logo';
import LinkedIn from 'svg/linkedin.svg';
import YouTube from 'svg/youtube-brands.svg';
import Twitter from 'svg/twitter-brands.svg';
import YouKu from 'svg/youku-brands.svg';
import Container from 'components/Container';
import LanguagePicker from 'components/LanguagePicker';
import EmailListSignup from 'components/EmailListSignup';
import './Footer.module.css';

const SOCIAL_ICONS = {
  linkedin: <LinkedIn />,
  youtube: <YouTube />,
  twitter: <Twitter />,
  youku: <YouKu />,
};

const Footer = ({ intl }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            careersURL
            socialProfiles {
              type
              url
              locale
            }
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          title,
          careersURL,
          socialProfiles,
        },
      },
    }) => (
      <Container>
        <footer styleName="footer">
          <div styleName="footer-columns">
            <div styleName="footer-column">
              <Logo styleName="logo" />
            </div>

            <div styleName="footer-column">
              <h5 styleName="column-heading">
                {intl.formatMessage({ id: 'common.language' })}
              </h5>

              <ul>
                <li>
                  <LanguagePicker />
                </li>
              </ul>

              <h5 styleName="column-heading">
                {intl.formatMessage({ id: 'common.follow-us' })}
              </h5>

              <div styleName="social-links">
                {socialProfiles
                  .filter(({ locale }) => locale === intl.locale)
                  .map(({ url, type }) => (
                    <a
                      styleName="social-link"
                      href={url}
                      key={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn profile"
                    >
                      <div styleName="icon">
                        {SOCIAL_ICONS[type] || type}
                      </div>
                    </a>
                  ))}
              </div>
            </div>

            <div styleName="footer-column">
              <h5 styleName="column-heading">
                {intl.formatMessage({ id: 'common.solutions' })}
              </h5>
              <ul>
                <li>
                  <Link
                    to="/"
                    aria-label={intl.formatMessage({ id: 'common.brainframe' })}
                  >
                    {intl.formatMessage({ id: 'common.brainframe' })}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/synthall/"
                    aria-label={intl.formatMessage({ id: 'common.synthall' })}
                  >
                    {intl.formatMessage({ id: 'common.synthall' })}
                  </Link>
                </li>
              </ul>

              <h5 styleName="column-heading">
                {intl.formatMessage({ id: 'common.company' })}
              </h5>
              <ul>
                <li>
                  <Link
                    to="/about/"
                    aria-label={intl.formatMessage({ id: 'common.about-footer' })}
                  >
                    {intl.formatMessage({ id: 'common.about-footer' })}
                  </Link>
                </li>

                {careersURL && (
                  <li>
                    <a
                      href={careersURL}
                      aria-label={intl.formatMessage({ id: 'common.careers' })}
                    >
                      {intl.formatMessage({ id: 'common.careers' })}
                    </a>
                  </li>
                )}
              </ul>
            </div>

            <div styleName="footer-column" data-column="contact">
              <h5 styleName="column-heading">
                {intl.formatMessage({ id: 'common.contact' })}
              </h5>

              <ul>
                <li styleName="contact-item">
                  <div>
                    {intl.formatMessage({ id: 'common.mailAddress' })}
                  </div>
                </li>

                <li styleName="contact-item">
                  <div>
                    <a
                      href={`mailto:${intl.formatMessage({ id: 'common.emailAddress' })}`}
                      rel="noopener noreferrer"
                      aria-label="Email address"
                    >
                      {intl.formatMessage({ id: 'common.emailAddress' })}
                    </a>
                  </div>
                </li>

                <li styleName="align-center">
                  <div styleName="vertical-content">
                    {intl.formatMessage({ id: 'common.email-list-cta' })}

                    <EmailListSignup compact />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <span>
            ©
            {' '}
            {new Date().getFullYear()}
            {` ${title} `}
            {intl.formatMessage({ id: 'common.all-rights' })}
          </span>
        </footer>
      </Container>
    )}
  />
);

Footer.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
