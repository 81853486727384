import { useStaticQuery, graphql } from 'gatsby';

import AotuAPI from 'AotuAPI';

export default () => {
  // Get data from siteMetadata to construct the URL
  const data = useStaticQuery(graphql`
    query AotuAPIInfo {
      site {
        siteMetadata {
          aotuAPIURL
        }
      }
    }
  `);
  const { aotuAPIURL } = data.site.siteMetadata;

  return new AotuAPI(aotuAPIURL);
};
