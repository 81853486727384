import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import AccountButton from 'components/AccountButton';
import NavLink from 'components/NavLink';
import Bars from 'svg/bars.svg';
import Times from 'svg/times.svg';
import './Mobile.module.css';

// TODO: clean up the desktop/mobile nav bars, DRY it up
const NavBarMobile = ({ routes, intl }) => {
  const [expanded, setExpanded] = useState(false);

  // disable scrolling when mobile nav bar is open
  useEffect(() => {
    if (expanded) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [expanded]);

  return (
    <>
      <div styleName="navbar-toggles">
        <button
          type="button"
          styleName="open"
          onClick={() => setExpanded(true)}
          aria-label="Open navigation menu"
          aria-hidden={expanded}
        >
          <Bars styleName="icon" />
        </button>

        <button
          type="button"
          styleName="close"
          onClick={() => setExpanded(false)}
          aria-label="Close navigation menu"
          aria-hidden={!expanded}
        >
          <Times styleName="icon" />
        </button>
      </div>

      <div styleName="nav-items-mobile" aria-hidden={!expanded}>
        {Object.values(routes).map(({
          title, route, external, subroutes,
        }) => (
          <div key={route} styleName="nav-link-container">
            <NavLink
              styleName="nav-link"
              to={route}
              external={external}
              aria-label={intl.formatMessage({ id: title })}
            >
              {intl.formatMessage({ id: title })}
            </NavLink>
            {subroutes && subroutes.map((subroute) => (
              <NavLink
                styleName="subroute"
                to={subroute.route}
                external={subroute.external}
                key={subroute.route}
                aria-label={intl.formatMessage({ id: subroute.title })}
              >
                {intl.formatMessage({ id: subroute.title })}
              </NavLink>
            ))}
          </div>
        ))}

        <div styleName="account-button-container">
          <AccountButton />
        </div>
      </div>
    </>
  );
};

const RouteType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  external: PropTypes.bool.isRequired,
  subroutes: PropTypes.arrayOf(PropTypes.object),
});

NavBarMobile.propTypes = {
  routes: PropTypes.arrayOf(RouteType).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(NavBarMobile);
