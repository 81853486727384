import React from 'react';
import PropTypes from 'prop-types';

import './Button.module.css';

const Button = ({ children, ...props }) => (
  <button styleName="button" type="button" {...props}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
