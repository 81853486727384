import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';
import { Location } from '@reach/router';

import ChevronRight from 'svg/chevron-left.svg';
import Button from 'components/Button';
import NavLink from 'components/NavLink';
import './Desktop.module.css';

function withPrefix(locale, route) {
  return `/${locale}${route}`;
}

function routeIsActive({ locale }, location, route, subroutes) {
  const currentRoute = withPrefix(locale, route);
  const routeActive = route.length > 1 && location.pathname.startsWith(currentRoute);

  if (routeActive || !subroutes) {
    return routeActive;
  }

  return routeActive || subroutes.some((subroute) => (
    location.pathname === withPrefix(locale, subroute.route)
  ));
}

const NavBarDesktop = ({ routes, intl }) => (
  <Location>
    {({ location }) => (
      <div styleName="nav-items">
        {Object.values(routes).map(({
          title, route, external, subroutes,
        }) => (
          <div key={route} styleName="nav-link-container" data-subroutes={!!subroutes}>
            <NavLink
              styleName="nav-link"
              to={route}
              external={external}
              aria-label={intl.formatMessage({ id: title })}
              aria-current={routeIsActive(intl, location, route, subroutes) && 'page'}
            >
              <div>
                <span>
                  {intl.formatMessage({ id: title })}

                  {subroutes && subroutes.length > 0 && (
                    <ChevronRight
                      style={{
                        transform: 'rotate(-90deg)',
                        width: '7px',
                        marginLeft: '0.5em',
                        vertialAlign: 'middle',
                      }}
                    />
                  )}
                </span>
              </div>
            </NavLink>

            <div styleName="dropdown">
              {subroutes && subroutes.map((subroute) => (
                <NavLink
                  styleName="nav-sublink"
                  to={subroute.route}
                  external={subroute.external}
                  key={subroute.route}
                  aria-label={intl.formatMessage({ id: subroute.title })}
                >
                  {intl.formatMessage({ id: subroute.title })}
                </NavLink>
              ))}
            </div>
          </div>
        ))}

        <Link to="/download/" aria-label={intl.formatMessage({ id: 'common.get_brainframe' })}>
          <Button
            type="button"
            style={{
              color: 'var(--purple-10)',
              padding: '0.5em 1.25em',
              fontSize: '0.9em',
              fontWeight: '600',
            }}
          >
            {intl.formatMessage({ id: 'common.get_brainframe' })}
          </Button>
        </Link>
      </div>
    )}
  </Location>
);

NavBarDesktop.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      external: PropTypes.bool.isRequired,
      subroutes: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(NavBarDesktop);
