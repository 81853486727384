import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import SiteNav from 'components/SiteNav';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';

const Layout = ({ children, navbar, intl }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet htmlAttributes={{ lang: intl.locale }} />

        <SiteNav />

        <NavBar title={data.site.siteMetadata.title} {...navbar} />

        <main>
          {children}
        </main>

        <Footer />
      </>
    )}
  />
);

const NavBarOptions = PropTypes.shape({
  showBackground: PropTypes.bool,
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  intl: intlShape.isRequired,
  navbar: NavBarOptions,
};

Layout.defaultProps = {
  navbar: {},
};

export default injectIntl(Layout);
