import React, { useContext } from 'react';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';
import Auth from '@aws-amplify/auth';

import AuthContext from 'context/AuthContext';
import SigninIcon from 'svg/sign-in-alt-solid.svg';
import './AccountButton.module.css';

/**
 * A multi-function button that offers to sign a user in if they're not signed
 * in already or bring them to the account page when they are.
 */
const AccountButton = ({ intl }) => {
  const signedIn = useContext(AuthContext);

  if (signedIn === null) {
    // We're waiting for account information
    return (
      <div>
        {intl.formatMessage({ id: 'common.loading' })}
      </div>
    );
  }

  if (signedIn) {
    // The user is logged in, be an account page button
    return (
      <Link to="/account" aria-label={intl.formatMessage({ id: 'common.account' })}>
        {intl.formatMessage({ id: 'common.account' })}
      </Link>
    );
  }

  // The user is logged out, be a sign in button
  return (
    <button
      styleName="sign-in-button"
      type="button"
      onClick={() => Auth.federatedSignIn()}
      aria-label={intl.formatMessage({ id: 'common.sign-in' })}
    >
      {intl.formatMessage({ id: 'common.sign-in' })}

      <SigninIcon />
    </button>
  );
};

AccountButton.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AccountButton);
