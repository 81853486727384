import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl } from 'gatsby-plugin-intl';

/**
 * A component that wraps either a Gatsby Link component or an HTML <a> tag
 * depending on if the link is to an external resource.
 */
const NavLink = ({
  children, to, external, ...props
}) => {
  if (external) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  /** The path to link to. */
  to: PropTypes.string.isRequired,
  /**
   * If true, this link points to a page that is not managed by Gatsby. This
   * could be a link to a different domain or a link to a non-Gatsby page on
   * this domain.
   */
  external: PropTypes.bool,
};

NavLink.defaultProps = {
  external: false,
};

export default injectIntl(NavLink);
