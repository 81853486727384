import { useState } from 'react';

export const STATUS_ERROR = 'error';
export const STATUS_SENDING = 'sending';
export const STATUS_SENT = 'sent';

export default (callback, defaultValues = {}) => {
  const [values, setValues] = useState(defaultValues);
  const [status, setStatus] = useState(null);

  const handleChange = (event) => {
    event.persist();

    setValues((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setStatus(STATUS_SENDING);

    return callback(values)
      .then(() => {
        setStatus(STATUS_SENT);
        setValues({});
      })
      .catch(() => {
        setStatus(STATUS_ERROR);
      });
  };

  return [handleChange, handleSubmit, values, status];
};
