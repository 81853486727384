import { useStaticQuery, graphql } from 'gatsby';

const CompanyNames = { AOTU: 'Aotu', DILILI_LABS: 'Dilili Labs' };

/**
 * Gets the statically configured company name that's in use for this
 * deployment. All company logos and mentions of the company should check this
 * value.
 */
function useCompanyName() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  const { companyName } = data.site.siteMetadata;

  switch (companyName.toLowerCase()) {
    case 'aotu':
      return CompanyNames.AOTU;
    case 'dilililabs':
      return CompanyNames.DILILI_LABS;
    default:
      throw Error(`Invalid company name value: ${companyName}`);
  }
}

export { CompanyNames, useCompanyName };
