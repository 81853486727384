import React from 'react';
import PropTypes from 'prop-types';

import './Container.module.css';

const Container = ({ children, ...props }) => (
  <div styleName="container" {...props}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
