import React from 'react';

import { CompanyNames, useCompanyName } from 'hooks/companyName';
import LogoDililiLabs from 'svg/logo-dilililabs.svg';
import LogoAotu from 'svg/logo-aotu.svg';

const Logo = ({ ...props }) => {
  const companyName = useCompanyName();

  if (companyName === CompanyNames.DILILI_LABS) {
    return <LogoDililiLabs {...props} />;
  }
  return <LogoAotu {...props} />;
};

export default Logo;
