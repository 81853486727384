import React from 'react';
import { useLocation } from '@reach/router';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/Container';
import NavLink from 'components/NavLink';
import AccountButton from 'components/AccountButton';
import './SiteNav.module.css';

const SiteNav = ({ intl }) => {
  const { pathname } = useLocation();
  const { site: { siteMetadata: { forumURL, docsURL } } } = useStaticQuery(graphql`
    query SiteNavMeta {
      site {
        siteMetadata {
          forumURL
          docsURL
        }
      }
    }
  `);

  const ROUTES = [
    { label: 'common.forum', route: forumURL, external: true },
    { label: 'common.docs', route: docsURL, external: true },
  ];

  // TODO: Update switching between light/dark bg
  return (
    <div styleName="site-nav" data-bg-light={['/en/', '/ch/'].indexOf(pathname) < 0}>
      <Container>
        <div styleName="links">
          {Object.values(ROUTES).map(({ label, route, external }) => (
            <NavLink
              to={route}
              external={external}
              aria-label={intl.formatMessage({ id: label })}
              key={route}
            >
              <div>
                {intl.formatMessage({ id: label })}
              </div>
            </NavLink>
          ))}

          <AccountButton />
        </div>
      </Container>
    </div>
  );
};

SiteNav.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(SiteNav);
