import Auth from '@aws-amplify/auth';

async function getSessionToken() {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

/**
 * Provides methods that access the Lambda APIs.
 */
class AotuAPI {
  constructor(url) {
    this.url = url;
  }

  /**
   * Gets policy metadata for the currently logged in user.
   *
   * @returns {Promise<{}>} - Policy metadata
   */
  async getUserPolicy() {
    const endpoint = `${this.url}/brainframe/policy`;

    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: await getSessionToken(),
      },
    });
    const body = await response.json();

    if (!response.ok) {
      throw new Error(`Error code ${response.status} while fetching policy: ${body}`);
    }

    return body.data.attributes.metadata;
  }

  /**
   * Generates a license key on the server and provides a URL where it can be
   * downloaded.
   *
   * @returns {Promise<string>} - A URL where the license key can be downloaded
   */
  async getLicenseKeyURL() {
    const endpoint = `${this.url}/brainframe/license-key`;

    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: await getSessionToken(),
      },
    });

    const blob = await response.blob();

    if (!response.ok) {
      throw new Error(`Error code ${response.status} while generating license key: ${blob}`);
    }

    return URL.createObjectURL(blob);
  }

  /**
   * Subscribes to a mailing list.
   *
   * @param listID - The ID of the mailing list to subscribe to
   * @param email - The email address to subscribe to
   * @param customFields - Any additional metadata about the subscriber
   * @returns {Promise<void>}
   */
  async subscribe(listID, email, customFields) {
    const endpoint = `${this.url}/email-list/subscribe`;

    const body = {
      list_id: listID,
      contact: {
        email,
      },
      custom_fields: customFields,
    };

    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error code ${response.status} while subscribing to mailing list`);
    }
  }

  /**
   * @returns {string} - The URL the contact form should submit to
   */
  getContactFormSubmissionEndpoint() {
    return `${this.url}/contact`;
  }
}

export default AotuAPI;
