import React from 'react';
import { injectIntl, intlShape, changeLocale } from 'gatsby-plugin-intl';

import Button from 'components/Button';
import './LanguagePicker.module.css';

const options = [{
  label: 'E',
  value: 'en',
}, {
  label: '中',
  value: 'zh',
}];

const LanguagePicker = ({ intl }) => (
  <div
    styleName="language-picker"
    title={intl.formatMessage({ id: 'common.change-language' })}
  >
    {options.map(({ label, value }) => (
      <Button
        type="button"
        onClick={() => changeLocale(value)}
        aria-current={value === intl.locale}
        key={value}
      >
        {label}
      </Button>
    ))}
  </div>
);

LanguagePicker.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LanguagePicker);
