import React from 'react';
import PropTypes from 'prop-types';
import { Link, injectIntl, intlShape } from 'gatsby-plugin-intl';

import Container from 'components/Container';
import Logo from 'components/Logo';
import NavBarMobile from './Mobile';
import NavBarDesktop from './Desktop';
import './NavBar.module.css';

// TODO: could move this route definition to the siteMetadata
const ROUTES = [
  {
    title: 'common.solutions',
    route: '/',
    external: false,
    subroutes: [{
      title: 'common.brainframe',
      route: '/',
      external: false,
    }, {
      title: 'common.synthall',
      route: '/synthall/',
      external: false,
    }],
  },
  { title: 'common.about-header', route: '/about/', external: false },
  { title: 'common.contact', route: '/contact/', external: false },
  { title: 'common.pricing', route: '/pricing/', external: false },
  { title: 'common.blog_news', route: '/blog/', external: false },
];

const NavBar = ({ intl, showBackground }) => (
  <div styleName="container" data-background={showBackground}>
    <Container>
      <nav styleName="nav">
        <Link to="/" aria-label={intl.formatMessage({ id: 'common.home' })}>
          <Logo styleName="logo" />
        </Link>

        <NavBarMobile routes={ROUTES} />
        <NavBarDesktop routes={ROUTES} />
      </nav>
    </Container>
  </div>
);

NavBar.propTypes = {
  intl: intlShape.isRequired,
  showBackground: PropTypes.bool,
};

NavBar.defaultProps = {
  showBackground: false,
};

export default injectIntl(NavBar);
